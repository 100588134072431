import React, { useState, useEffect } from 'react';
import axios from "axios";
import { API_BASE_URL, CRM_URL, SITE_KEY,FORM_AUTH_TOKEN } from "../config/config";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

import ReactRecaptcha3 from 'react-google-recaptcha3';
import { xssSafeString, sanitizeInput, getApiAccessToken } from '../utils/helper';

export default function ColocationPopup({ colocationData }) {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const [getAuthToken, setAuthToken] = useState(null);
  useEffect(() => {
    const fetchToken = async () => {
      try {
        var result = await getApiAccessToken();
        setAuthToken(result.token);
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };
    fetchToken();
    const tokenInterval = setInterval(fetchToken, 60000);
    return () => clearInterval(tokenInterval);
  }, [])

  useEffect(() => {
    ReactRecaptcha3.init(SITE_KEY).then(
      (status) => {
 
      }
    );
  }, [])


  const handleSubmit = (newNav) => {
    setErrorMsg(false);
    ReactRecaptcha3.getToken().then(
      (token) => {
        let data = {
          Details: newNav,
          token: token
        }
        
        setLoading(true);
        axios.post(`${CRM_URL}?cmd=enterprise_inquiry`, data,{
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAuthToken}`
          } 
        }).then((response) => {
          console.log('response',response); 
          setLoading(false);
          setFormSubmitted(true);
          axios.post(`${API_BASE_URL}/api/website/createColocationInquiry`, data)
          setTimeout(() => {
            setFormSubmitted(false);
          }, 5000);
        }).catch((error) => {
          setErrorMsg(true);
        });
      });
  };

  const [navigation, setNav] = useState(null);
  const validationInquiry = useFormik({
    enableReinitialize: true,

    initialValues: {

      name: (navigation && navigation.name) || "",
      company_name: (navigation && navigation.company_name) || "",
      job_title: (navigation && navigation.job_title) || "",
      email: (navigation && navigation.email) || "",
      phone_no: (navigation && navigation.phone_no) || "",
      city: (navigation && navigation.city) || "",
      description: (navigation && navigation.description) || "",
    },
    validationSchema: Yup.object({

      name: xssSafeString("Please Enter Name").max(50, 'Must be 50 characters or less'),
      company_name: xssSafeString("Please Enter Company Name").max(50, 'Must be 50 characters or less'),
      job_title: xssSafeString("Please Enter Job Title").max(50, 'Must be 50 characters or less'),
      email: Yup.string()
      .required("Please Enter Email Address")
      .matches( 
        /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i,
        'Please enter a valid email address'
      ).max(50, 'Must be 50 characters or less').transform((value) => sanitizeInput(value)),                       
      phone_no: Yup.string()
        .required('Please Enter Phone Number')
        .matches(
          /^\d{10}$/,
          'Phone number must be exactly 10 digits'
        ).transform((value) => sanitizeInput(value)),
      city: xssSafeString("Please Enter City").max(50, 'Must be 50 characters or less'),
      description: xssSafeString("Please Enter Description"),
    }),

    onSubmit: (values) => {
      const urlParams = new URLSearchParams(window.location.search);
      const utmSource = urlParams.get('utm_source')|| '';
      const utmMedium = urlParams.get('utm_medium')|| '';

      const newNav = {
        name: sanitizeInput(values.name),
        email_address: sanitizeInput(values.email),
        comp_name: sanitizeInput(values.company_name),
        job_title: sanitizeInput(values.job_title),
        phone_no: sanitizeInput(values.phone_no),
        description: sanitizeInput(values.description),
        city: sanitizeInput(values.city),
        utm_source: sanitizeInput(utmSource),
        utm_medium: sanitizeInput(utmMedium)
      };

      handleSubmit(newNav)
      validationInquiry.resetForm();

    },
  });
  return (
    <>
      <div className="form-wrapper colocation_popupform">

        <Form
          id="colocation_popup"
          className={`${formSubmitted ? "submitted-success" : ""}${loading ? "loader-active" : ""}`}
          onSubmit={(e) => {
            e.preventDefault();
            validationInquiry.handleSubmit();
            return false;
          }}
        >
          <h2>Get In Touch</h2>
          <div className='item'>
            <label htmlFor="name">Name:</label>
            <Input type="text" id="name" name="name"
              onChange={validationInquiry.handleChange}
              onBlur={validationInquiry.handleBlur}
              value={validationInquiry.values.name || ""}
              invalid={
                validationInquiry.touched.name && validationInquiry.errors.name
                  ? true
                  : false
              }
            />
            {validationInquiry.touched.name && validationInquiry.errors.name ? (
              <FormFeedback type="invalid">
                {validationInquiry.errors.name}
              </FormFeedback>
            ) : null}
          </div>
          <div className='item'>
            <label htmlFor="company_name">Company Name:</label>
            <Input type="text" id="company_name" name="company_name"
              onChange={validationInquiry.handleChange}
              onBlur={validationInquiry.handleBlur}
              value={validationInquiry.values.company_name || ""}
              invalid={
                validationInquiry.touched.company_name && validationInquiry.errors.company_name
                  ? true
                  : false
              }
            />
            {validationInquiry.touched.company_name && validationInquiry.errors.company_name ? (
              <FormFeedback type="invalid">
                {validationInquiry.errors.company_name}
              </FormFeedback>
            ) : null}
          </div>
          <div className='item'>
            <label htmlFor="job_title">Job title:</label>
            <Input type="text" id="job_title" name="job_title"
              onChange={validationInquiry.handleChange}
              onBlur={validationInquiry.handleBlur}
              value={validationInquiry.values.job_title || ""}
              invalid={
                validationInquiry.touched.job_title && validationInquiry.errors.job_title
                  ? true
                  : false
              }
            />
            {validationInquiry.touched.job_title && validationInquiry.errors.job_title ? (
              <FormFeedback type="invalid">
                {validationInquiry.errors.job_title}
              </FormFeedback>
            ) : null}
          </div>
          <div className='item'>
            <label htmlFor="email">Email Address:</label>
            <Input type="text" id="email" name="email"
              onChange={validationInquiry.handleChange}
              onBlur={validationInquiry.handleBlur}
              value={validationInquiry.values.email || ""}
              invalid={
                validationInquiry.touched.email && validationInquiry.errors.email
                  ? true
                  : false
              }
            />
            {validationInquiry.touched.email && validationInquiry.errors.email ? (
              <FormFeedback type="invalid">
                {validationInquiry.errors.email}
              </FormFeedback>
            ) : null}
          </div>
          <div className='item'>
            <label htmlFor="phone_no">Phone Number:</label>
            <Input type="text" id="phone_no" name="phone_no"
              onChange={validationInquiry.handleChange}
              onBlur={validationInquiry.handleBlur}
              value={validationInquiry.values.phone_no || ""}
              invalid={
                validationInquiry.touched.phone_no && validationInquiry.errors.phone_no
                  ? true
                  : false
              }
            />
            {validationInquiry.touched.phone_no && validationInquiry.errors.phone_no ? (
              <FormFeedback type="invalid">
                {validationInquiry.errors.phone_no}
              </FormFeedback>
            ) : null}
          </div>
          <div className='item'>
            <label htmlFor="city">City:</label>
            <Input type="text" id="city" name="city"
              onChange={validationInquiry.handleChange}
              onBlur={validationInquiry.handleBlur}
              value={validationInquiry.values.city || ""}
              invalid={
                validationInquiry.touched.city && validationInquiry.errors.city
                  ? true
                  : false
              }
            />
            {validationInquiry.touched.city && validationInquiry.errors.city ? (
              <FormFeedback type="invalid">
                {validationInquiry.errors.city}
              </FormFeedback>
            ) : null}
          </div>
          <div className='item'>
            <label htmlFor="description">Description:</label>
            <textarea
              name="description"
              value={validationInquiry.values.description}
              onChange={validationInquiry.handleChange}
              onBlur={validationInquiry.handleBlur}
              maxLength={255}
              className={`form-control ${validationInquiry.touched.description && validationInquiry.errors.description ? 'is-invalid' : ''
                }`}
            ></textarea>
            {validationInquiry.touched.description && validationInquiry.errors.description ? (
              <FormFeedback type="invalid">
                {validationInquiry.errors.description}
              </FormFeedback>
            ) : null}
          </div>
          <div className='item submit-item'>
            <button type="submit" className="button btn-pacific-blue-colocations">Submit</button>
          </div>
          {errorMsg &&
            <div className="form-error-msg form-text-center">
              <span className='errorMsg'>Something Went Wrong!, Please try again later.</span>
            </div>
          }
          {formSubmitted && (
            <div className='thanks-msg'>
              <div className="main-container">
                <div className="check-container">
                  <div className="check-background">
                    <svg viewBox="0 0 65 51" fill="none" xmlns="https://www.w3.org/2000/svg">
                      <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                  <div className="check-shadow"></div>
                </div>
                <h4>"Thank you - We'll be in touch shortly to assist you."</h4>
              </div>
            </div>
          )}
          
          {loading && (
            <div className='loader-sec'>
              <div className="main-container">
                <div className="check-container">
                  <span class="loader"></span>
                </div>
              </div>
            </div>
          )}
        </Form>
      </div>
    </>
  );
}					