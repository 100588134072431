import React, { useEffect, useState } from "react";
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import ClientExperiences from "../../components/home/client-experiences";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import WhatWeOffer from "../../components/CommonSections/WhatWeOffer";
import Advantage from "../../components/CommonSections/Advantage";
import PageLoader from "../../components/home/pageload";
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgSmartCity from "../../assest/images/IshanSmartCity.png";
import { Link } from "react-router-dom";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";
import ColocationPopup from "../../components/colocation-popup";
import Accordion from '../../components/accordion';
import {
     Input,
     FormFeedback,
     Form, 
     Modal,
     ModalBody,
   } from "reactstrap";

export default function TestPage() {
     const handleClick = () => {
          window.scrollTo(0, 0);
     }

     const [ColocationChennai, setColocationChennai] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);

     useEffect(() => {
          const requestData = {  
               "service_tag": "colocation_chennai"
          };

          axios.post(`${API_BASE_URL}/api/smartcity/getsmartCity`, requestData).then((response) => {
               setColocationChennai(response);

               console.log('response',response);
               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;
               const networkServicesSettingsData = generalSettingsData.find((item) => item.tag === "smart_city_advantage") || null;
               const networkServicesSettingsResponse = JSON.parse(networkServicesSettingsData?.response || "{}");

               networkServicesSettingsResponse.advantages = networkServicesSettingsResponse;
               setSettingResponse(networkServicesSettingsResponse);
          }).catch((error) => { 
               <PageLoader />
          })
     }, [])

     const [modal, setModal] = useState(false);
     const [colocationData, setcolocationData] = useState(null);
     const toggle = () => {
       if (modal) {
         setModal(false);
       } else {
         setModal(true);
       }
     };
   
   
     const openModal = (colocationData) => {
       setModal(true);
       setcolocationData(colocationData);
     };   


     return (

          <>
               {ColocationChennai === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <SubServiceSchema title={ColocationChennai?.data?.data?.smart_city?.smart_city_banner_data
                              [0].title} url='https://ishantechnologies.com/systems-integration/smart-city-services' parentTitle='System Integration' parentUrl='https://ishantechnologies.com/systems-integration' />
                    <MetaTag data={ColocationChennai} pageKey="smart_city"/>
                         <div className="page_heading responsive_heading">
                              <div className="container">
                                   <HeaderTitle headerTitledata={ColocationChennai} pageKey="smart_city" />
                              </div>
                         </div>
                         <div className="video hero-banner">
                              <FrontVideo homeData={ColocationChennai} pageKey="smart_city" />
                              <div className="banner-content ">
                                   {ColocationChennai?.data?.data?.smart_city?.smart_city_banner_data
                              [0]?.banner_title && (
                                   <h2 className='home-heading'>
                                        {ColocationChennai?.data?.data?.smart_city?.smart_city_banner_data
                              [0].banner_title}
                                   </h2>
                                   )}

                                   {ColocationChennai?.data?.data?.smart_city?.smart_city_banner_data
                              [0]?.banner_img_desc && (
                                   <p className="para">
                                        {ColocationChennai?.data?.data?.smart_city?.smart_city_banner_data
                              [0].banner_img_desc}
                                   </p>
                                   )}

                                   {ColocationChennai?.data?.data?.smart_city?.smart_city_banner_data
                              [0]?.button_img_name && (
                                   <a href={ColocationChennai?.data?.data?.smart_city?.smart_city_banner_data
                              [0].button_url}>
                                        <button className="button hero-btn">
                                        {ColocationChennai?.data?.data?.smart_city?.smart_city_banner_data
                              [0]?.button_img_name}
                                        </button>
                                   </a>
                                   )}
                              </div>  
                         </div>

                         <div className="empowering section-space spin-wrap">
                              <div className="spin-round-wrap">
                                   <div className="spin-round color-navy-blue">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>

                              <div className="container">
                                   <div className="empowering-inner">
                                        <div className="empowering-left">
                                             {<img src={ImgSmartCity} alt="Smart City" height={60} width={60} loading="lazy"/>}
                                        </div>
                                        <div className="empowering-right" dangerouslySetInnerHTML={{ __html: ColocationChennai?.data?.data?.smart_city?.smart_city_information_data[0]?.description }}>
                                        </div>
                                   </div>
                                   <div className="colocation_popup"> 
          
                                        <button className="button getintouch" onClick={() => openModal()}>Get In Touch</button>
                                   </div>
                              </div>

                              <div className="spin-round-wrap">
                                   <div className="spin-round">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>
                         </div>

                         <div className="weoffer-bgdark">
                              <WhatWeOffer title={settingResponse?.what_we_offer} data={ColocationChennai?.data?.data?.smart_city?.smart_city_offer_data} />
                         </div>

                         <Advantage title={settingResponse?.advantages} data={ColocationChennai?.data?.data?.smart_city?.smart_city_advantages_data} action='colocation' />
                         <div className="frequently section-space">
                              <div className="container">
                                   <h2 className="supercharge-heading">Frequently Asked Questions (FAQs)</h2>
                                   <Accordion items={ColocationChennai?.data?.data?.smart_city?.smart_city_services_faq_data} />
                              </div>
                         </div>

                         <div className="supercharge supercharge-left">
                              <div className="container">
                                   <h2 className="supercharge-heading" style={{marginBottom:'2px'}}>{ColocationChennai?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.title}</h2>
                                   <p style={{ color: '#fff' }}> Ready to revolutionise your infrastructure with Ishan Technologies' Colocation Services in Chennai? </p>
                                   <div className="supercharge-btns">
                                        {ColocationChennai?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1 &&
                                             <Link to={ColocationChennai?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_url_1}>
                                                  <button className="getintouch" onClick={handleClick}>{ColocationChennai?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1?.toUpperCase()}</button>
                                             </Link>
                                        }
                                       
                                        {ColocationChennai?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_2 &&
                                             <Link to={ColocationChennai?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_url_2} target="_blank">
                                                  <button className="getintouch" onClick={handleClick}>{ColocationChennai?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_2?.toUpperCase()}</button>
                                             </Link>
                                        }
                                   </div>
                              </div>
                         </div>
                         
                         <Modal isOpen={modal} toggle={toggle}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); toggle(); }}>×</Link>
                              <ColocationPopup colocationData={colocationData} />
                              </ModalBody>
                         </Modal>
                    </>
               )} 
          </>
     );
}