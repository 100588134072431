import React, { useState, useRef, useEffect } from 'react'
import { Link } from "react-router-dom";
import chevIC from "../../assest/images/chevron-down.svg";

const WhatWeOffer = ({ title, data = [] }) => {
     const whatWeAreRef = useRef(null);
     const [activeTab, setActiveTab] = useState(1);
     const handleTabClick = (tabNumber) => {
          setActiveTab(tabNumber);
          scrollToRef(whatWeAreRef)
     };
     const handleClick = () => {
          window.scrollTo(0, 0);
     }
     function scrollToRef(ref) {
          if (ref.current) {
               const topOffset = 320;
               const topPosition = ref.current.getBoundingClientRect().top;
               window.scrollTo({
                    top: window.scrollY + topPosition - topOffset,
                    behavior: "smooth",
               });
          }
     }

     const [activeIndex, setActiveIndex] = useState();
     const toggleAccordion = (index) => {
          if (activeIndex === index) {
            setActiveIndex(null);
          } else {
            setActiveIndex(index);
          }
     };

     return (
          <div className="weoffer section-space">
               <div className='for-desk'>
                    <div className="container">
                         <h2 className="weoffer-heading">What We Offer</h2>
                         <div className="weoffer-inner">
                              <div className="weoffer-inner-tab">
                                   <ul className="tabs short-tabs">

                                        {data.map((offer, index) => (

                                             <li key={index} className={activeTab === index + 1 ? "actived" : ""}>
                                                  <button
                                                       className={activeTab === index + 1 ? "tab-btn active" : "tab-btn"}
                                                       onClick={() => handleTabClick(index + 1)}
                                                       tabIndex={0}
                                                  >
                                                       {offer.tag_title}
                                                  </button>
                                             </li>

                                        ))}
                                   </ul>
                              </div>
                              <div className="weoffer-details" ref={whatWeAreRef}>
                                   <div className="tab-content">
                                        {data.map((offer, index) => (
                                             <React.Fragment key={index}>
                                                  {activeTab === index + 1 &&
                                                       <div className="sec-row" key={index}>
                                                            <div className="sec-col rgt-col">
                                                                 {<img src={offer.image} alt={offer.altText} height={351} width={551} loading="lazy"/>}
                                                            </div>
                                                            <div className="sec-col lft-col">
                                                                 {offer.icon !== null && offer.icon !== "" &&
                                                                      <div className="svg-col">
                                                                           <img src={offer.icon} alt={offer.iconAltText}  width={59} height={60} loading="lazy" />
                                                                      </div>
                                                                 }
                                                                 <div className="cntnt-col">
                                                                      <h2>{offer.tag_title}</h2>
                                                                      <div dangerouslySetInnerHTML={{ __html: offer.description }} />
                                                                      {offer.button_url !== null && offer.button_url !== "" &&
                                                                           <Link to={offer.button_url} onClick={handleClick}>
                                                                                <button className="button">Know more</button>
                                                                           </Link>
                                                                      }
                                                                 </div>

                                                            </div>
                                                            {
                                                                 offer.products &&
                                                                 offer.products.length > 0 &&
                                                                 (
                                                                      <div className="weoffer-point">
                                                                           <h2 className="cntnt-heading">Product</h2>
                                                                           <div className="weoffer-point-inner">
                                                                                {offer.products.map(product => (
                                                                                     <div className="point_inner" key={product.id}>
                                                                                          <span className="cntnt-head">{product.title}:</span>
                                                                                          <p>{product.description}</p>
                                                                                     </div>
                                                                                ))}
                                                                           </div>
                                                                      </div>
                                                                 )
                                                            }
                                                       </div>
                                                  }
                                             </React.Fragment>
                                        ))}
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               <div className="for-mobile">
                    <div className="container">
                    <h2 className="weoffer-heading">What We Offer</h2>
                    {data && data.length > 0 && ( 
                    <div className="accordion">
                         {data.map((item, index) => (
                         <div className="accordion-item" key={index}>
                         <button
                              className={`accordion-title ${activeIndex === index ? 'active' : ''}`}
                              onClick={() => toggleAccordion(index)}
                         >
                              {item.tag_title}
                              {/* <span className="expand-bar"></span> */}
                              <img src={chevIC} alt="" className="chev-ic" />
                         </button>
                         <div className={`accordion-content ${activeIndex === index ? 'active' : ''}`}>
                              {activeIndex === index && (
                              <> 
                                   <div>
                                        <img src={item.image} alt="Accordion related" className="accordion-image" />
                                   </div>
                                   
                                   <div className="mt-4" dangerouslySetInnerHTML={{ __html: item.description }} />
                              </>
                              )}
                         </div>
                         </div>
                         ))}
                    </div>
                    )}
                    </div>
                    
               </div>
     </div>

     )
}

export default WhatWeOffer