import React, { useEffect, useState } from "react";
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import ClientExperiences from "../../components/home/client-experiences";
import { API_BASE_URL } from "../../config/config";
import axios from "axios";
import WhatWeOffer from "../../components/CommonSections/WhatWeOffer";
import Advantage from "../../components/CommonSections/Advantage";
import PageLoader from "../../components/home/pageload";
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgCarrierBased from "../../assest/images/IshanCarrierBasedCloud.png";
import { Link } from "react-router-dom";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";

export default function CarrierBasedCloud() {
     const handleClick = () => {
          window.scrollTo(0, 0);
     }
     const [carrierBasedCloudData, setCarrierBasedCloudData] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);

     useEffect(() => {
          const requestData = {
               "service_tag": "cbci"
          };

          axios.post(`${API_BASE_URL}/api/carrierbasedservices/getCarrierBasedServices`, requestData).then((response) => {

               setCarrierBasedCloudData(response);

               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;

               const networkServicesSettingsData = generalSettingsData.find((item) => item.tag === "carrier_based_services") || null;
               const networkServicesSettingsResponse = JSON.parse(networkServicesSettingsData?.response || "{}");

               setSettingResponse(networkServicesSettingsResponse);
          }).catch((error) => {
               <PageLoader />
          })
     }, [])


     return (

          <>
               {carrierBasedCloudData === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <SubServiceSchema title={carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_banner_data
                              [0].title} url='https://ishantechnologies.com/network-services/Carrier-based-cloud' parentTitle='Network Services' parentUrl='https://ishantechnologies.com/network-services' />
                     <MetaTag data={carrierBasedCloudData} pageKey="carrier_based_services"/>
                         <div className="page_heading responsive_heading">
                              <div className="container">
                                   <HeaderTitle headerTitledata={carrierBasedCloudData} pageKey="carrier_based_services" />
                              </div>
                         </div>
                         <div className="video hero-banner">
                              <FrontVideo homeData={carrierBasedCloudData} pageKey="carrier_based_services" />
                              <h2 className="home-heading">{carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_banner_data
                              [0].banner_title}</h2>

                              <div className="banner-content ">
                                   {carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_banner_data
                              [0]?.banner_title && (
                                   <h2 className='home-heading'>
                                        {carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_banner_data
                              [0].banner_title}
                                   </h2>
                                   )}

                                   {carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_banner_data
                              [0]?.banner_img_desc && (
                                   <p className="para">
                                        {carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_banner_data
                              [0].banner_img_desc}
                                   </p>
                                   )}

                                   {carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_banner_data
                              [0]?.button_img_name && (
                                   <a href={carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_banner_data
                              [0].button_url}>
                                        <button className="button hero-btn">
                                        {carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_banner_data
                              [0]?.button_img_name}
                                        </button>
                                   </a>
                                   )}
                              </div> 
                         </div>

                         <div className="empowering section-space spin-wrap">
                              <div className="spin-round-wrap">
                                   <div className="spin-round color-navy-blue">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>

                              <div className="container">
                                   <div className="empowering-inner">
                                        <div className="empowering-left">
                                             {<img src={ImgCarrierBased} alt="Carrier Based" height={60} width={60} loading="lazy"/>}
                                        </div>
                                        <div className="empowering-right" dangerouslySetInnerHTML={{ __html: carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_information_data[0]?.description }}>
                                        </div>
                                   </div>
                              </div>

                              <div className="spin-round-wrap">
                                   <div className="spin-round">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>
                         </div>

                         <div className="weoffer-bgdark">
                              <WhatWeOffer title={settingResponse?.what_we_offer} data={carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_offer_data} />
                         </div>

                         <Advantage title={settingResponse?.advantages} data={carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_advantages_data} />

                         <div className="client-experiences section-space">
                              <ClientExperiences homeData={carrierBasedCloudData} pageKey="carrier_based_services" tag="cbci" />
                         </div>

                         <div className="supercharge supercharge-left">
                              <div className="container">
                                   <h2 className="supercharge-heading">{carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_cta_banner_data[0]?.title}</h2>
                                   <div className="supercharge-btns">

                                        {carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_cta_banner_data[0]?.button_title_1 &&
                                             <Link to={carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_cta_banner_data[0]?.button_url_1}>
                                                  <button className="getintouch" onClick={handleClick}>{carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_cta_banner_data[0]?.button_title_1?.toUpperCase()}</button>
                                             </Link>
                                        }
                                        {carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_cta_banner_data[0]?.button_title_2 &&
                                             <Link to={carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_cta_banner_data[0]?.button_url_2} target="_blank">
                                                  <button className="getintouch" onClick={handleClick}>{carrierBasedCloudData?.data?.data?.carrier_based_services?.carrier_based_services_cta_banner_data[0]?.button_title_2?.toUpperCase()}</button>
                                             </Link>
                                        }
                                   </div>
                              </div>
                         </div>
                    </>
               )}
          </>
     );
}