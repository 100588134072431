import DOMPurify from 'dompurify';
import * as Yup from 'yup';
import { API_BASE_URL } from "../config/config";
import axios from 'axios';
export const sanitizeInput = (value) => {
  return DOMPurify.sanitize(value);
};

export const xssSafeString = (message) => {
  return Yup.string()
    .trim()
    .required(message)
    .max(250, 'Must be 250 characters or less')
    .test('no-xss', 'Invalid characters detected', (value) => {
      const xssRegex = /(<script.*?>.*?<\/script>|javascript:|<.*?>|&.*?;|eval\(|alert\(|on[a-z]+\s*=\s*['"]?)/i;
      return !xssRegex.test(value || '');
    })
    .transform((value) => sanitizeInput(value));
};


export const getApiAccessToken = async () => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/auth/get_token`, {});
    return response.data;
  } catch (error) {
    console.error('Error fetching token:', error);
  }
};

