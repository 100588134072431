import React, { useState, useEffect } from 'react';
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import { Link } from "react-router-dom";
import axios from "axios";
import PageLoader from '../../components/home/pageload';
import MetaTag from "../../components/CommonSections/MetaTag";
import { API_BASE_URL, CRM_URL, SITE_KEY,FORM_AUTH_TOKEN } from "../../config/config";
import { useFormik } from "formik";
import * as Yup from "yup";
import PlansPopup from "../contacts/plans-popup";
import {
  Input,
  FormFeedback,
  Form, 
  Modal,
  ModalBody,
} from "reactstrap";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";
import { xssSafeString, sanitizeInput, getApiAccessToken } from '../../utils/helper';


export default function Plans() {

  const [getAuthToken, setAuthToken] = useState(null);
  useEffect(() => {
    const fetchToken = async () => {
      try {
        var result = await getApiAccessToken();
        setAuthToken(result.token);
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };
    fetchToken();
    const tokenInterval = setInterval(fetchToken, 60000);
    return () => clearInterval(tokenInterval);
  }, [])

  useEffect(() => {
    ReactRecaptcha3.init(SITE_KEY).then(
      (status) => {

      }
    );
  }, [])

  const [modal, setModal] = useState(false);
  const [planData, setPlanData] = useState(null);
  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };


  const openModal = (planData) => {
    setModal(true);
    setPlanData(planData);
  };


  const [formSubmitted, setFormSubmitted] = useState(false);
  const [navigation, setNav] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {

      id: (navigation && navigation.id) || "",
      service_id: (navigation && navigation.service_id) || "",
      contact_number: (navigation && navigation.contact_number) || "",

    },
    validationSchema: Yup.object({
      service_id: xssSafeString("Please Enter Service Id").max(50, 'Must be 50 characters or less'),
      contact_number: Yup.string()
        .required('Please Enter Phone Number')
        .matches(
          /^\d{10}$/,
          'Phone number must be exactly 10 digits'
        ).transform((value) => sanitizeInput(value)),
    }),

    onSubmit: (values) => {

      const newNav = {
        service_id: sanitizeInput(values.service_id),
        contact_number: sanitizeInput(values.contact_number),

      };

      handleSubmit(newNav)


      validation.resetForm();

    },
  });

  const handleSubmit = (newNav) => {
    setErrorMsg(false);
    ReactRecaptcha3.getToken().then(
      (token) => {
        let data = {
          "Details": newNav,
          token: token
        };

        axios.post(`${CRM_URL}?cmd=referral_form`, data,{
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAuthToken}`
          }
        }).then((response) => {

          setFormSubmitted(true);
          axios.post(`${API_BASE_URL}/api/website/createReferralForm`, data)
          setTimeout(() => {
            setFormSubmitted(false);
          }, 5000);
        }).catch((error) => {
          setErrorMsg(true);
        });
      })
  };

  useEffect(() => {
    getData();
  }, [])

  const [highSpeedPlanServicesData, setHighSpeedPlanServicesData] = useState(null);
  const [settingResponse, setSettingResponse] = useState(null);

  const getData = () => {
    axios.post(`${API_BASE_URL}/api/plans/getAllPlans`).then((response) => {

      setHighSpeedPlanServicesData(response);

      const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;

      const highSpeedPlanServicesSettingsData = generalSettingsData.find((item) => item.tag === "plans_offer") || null;
      const highSpeedPlanServicesSettingsResponse = JSON.parse(highSpeedPlanServicesSettingsData?.response || "{}");

      setSettingResponse(highSpeedPlanServicesSettingsResponse);
    }).catch((error) => {
      <PageLoader />
    })
  }
  return (

    <>
      {highSpeedPlanServicesData === null ? (
        <PageLoader />
      ) : (
        <>
        <SubServiceSchema title={highSpeedPlanServicesData?.data?.data?.plans?.plans_banner_data
            [0].title} url='https://ishantechnologies.com/network-services/high-speed-broadband/plans' parentTitle='Network Services' parentUrl='https://ishantechnologies.com/network-services' />
        <MetaTag data={highSpeedPlanServicesData} pageKey="plans"/>
          <div className="page_heading responsive_heading">
            <div className="container">
              <HeaderTitle headerTitledata={highSpeedPlanServicesData} pageKey="plans" />
            </div>
          </div>
          <div className="video hero-banner">
            <FrontVideo homeData={highSpeedPlanServicesData} pageKey="plans" />
            <h2 className="home-heading">{highSpeedPlanServicesData?.data?.data?.plans?.plans_banner_data
            [0].banner_title}</h2>

            <div className="banner-content ">
              {highSpeedPlanServicesData?.data?.data?.plans?.plans_banner_data
            [0]?.banner_title && (
              <h2 className='home-heading'>
                    {highSpeedPlanServicesData?.data?.data?.plans?.plans_banner_data
            [0].banner_title}
              </h2>
              )}

              {highSpeedPlanServicesData?.data?.data?.plans?.plans_banner_data
            [0]?.banner_img_desc && (
              <p className="para">
                    {highSpeedPlanServicesData?.data?.data?.plans?.plans_banner_data
            [0].banner_img_desc}
              </p>
              )}

              {highSpeedPlanServicesData?.data?.data?.plans?.plans_banner_data
            [0]?.button_img_name && (
              <a href={highSpeedPlanServicesData?.data?.data?.plans?.plans_banner_data
            [0].button_url}>
                    <button className="button hero-btn">
                    {highSpeedPlanServicesData?.data?.data?.plans?.plans_banner_data
            [0]?.button_img_name}
                    </button>
              </a>
              )}
            </div>  
          </div>
          <div className="cms plan">
            <div className="container">
              <div className="breadcrumb">
                <ul>
                  <li>Network services</li>
                  <li>High-speed broadband</li>
                  <li>High-speed broadband plans</li>
                </ul>
              </div>

              <h2>{settingResponse?.title}</h2>

              {highSpeedPlanServicesData?.data?.data?.plans?.plans_plan_data !== null &&
                highSpeedPlanServicesData?.data?.data?.plans?.plans_plan_data.length > 0 && (
                  <div className="plans-wrap">
                    {highSpeedPlanServicesData.data.data.plans.plans_plan_data.map((plan, index) => (
                      <div className="plan-item" key={index}>
                        <div className="plan-inner">
                          {plan?.tag !== "" && plan?.tag !== null && (
                            <span className="offer-badge">{plan?.tag}</span>
                          )}
                          <span className="speed">{plan?.plan}</span>
                          <div dangerouslySetInnerHTML={{ __html: plan?.description }}>

                          </div>
                          <span className="price">{plan?.price}</span>

                          <button className="button btn-blue-eyed" onClick={() => openModal(plan.plan)}>{plan?.button_title}</button>

                        </div>
                      </div>
                    ))}
                  </div>
                )}

              <Modal isOpen={modal} toggle={toggle}>
                <ModalBody>
                  <Link className="close1" onClick={(e) => { e.preventDefault(); toggle(); }}>×</Link>
                  <PlansPopup planData={planData} />
                </ModalBody>
              </Modal>

            </div>

            {highSpeedPlanServicesData?.data?.data?.plans?.plans_add_ons_data !== null && highSpeedPlanServicesData?.data?.data?.plans?.plans_add_ons_data.length > 0 && (
              <div className="add-ons gradiant-bg ons-add">
                <div className="container">
                  <h2>{highSpeedPlanServicesData?.data?.data?.plans?.plans_add_ons_data[0]?.title}</h2>
                  <div className="action-link">
                    <img src={highSpeedPlanServicesData?.data?.data?.plans?.plans_add_ons_data[0].image} alt={highSpeedPlanServicesData?.data?.data?.plans?.plans_add_ons_data[0].altText}  loading="lazy"/>
                  </div>
                </div>
              </div>
            )}
            <div className="cashback">
              <div className="container">
                <div className="cashback-wrap">
                  <div className="lft-text">
                    <h2>Get Flat &#8377;250 Cashback on your next billing cycle for every successful referral </h2>
                    <span className="condition">*Referral amount will be credited after your referred <br></br>contact signs up for Ishan broadband plan</span>
                  </div>
                  <div className="rgt-form">
                    <div className="form-wrapper form-bg">
                      <Form className={formSubmitted && ("submitted-success")}
                        id="Referer"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}>
                        <div className='item'>
                          <label htmlFor="service_id">Enter your service ID</label>
                          <Input type="number" id="service_id" name="service_id" onChange={(e) => {
                              // Limit to 6 digits
                              const limitedValue = e.target.value.slice(0, 6);
                              validation.handleChange({
                                target: { id: 'service_id', value: limitedValue },
                              });
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.service_id || ""}
                            invalid={
                              validation.touched.service_id && validation.errors.service_id
                                ? true
                                : false
                            }
                          />
                          {validation.touched.service_id && validation.errors.service_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.service_id}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className='item'>
                          <label htmlFor="contact_number">Enter your friend’s contact number </label>
                          <Input type="text" id="contact_number" name="contact_number" onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contact_number || ""}
                            invalid={
                              validation.touched.contact_number && validation.errors.contact_number
                                ? true
                                : false
                            }
                          />
                          {validation.touched.contact_number && validation.errors.contact_number ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contact_number}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='item submit-item'>
                          <button type="submit" className='button btn-pacific-blue'>Submit</button>
                        </div>
                        {errorMsg &&
                          <div className="form-error-msg">
                            <span className='errorMsg'>Something Went Wrong!, Please try again later.</span>
                          </div>
                        }
                        {formSubmitted && (
                          <div className='thanks-msg'>
                            <div className="main-container">
                              <div className="check-container">
                                <div className="check-background">
                                  <svg viewBox="0 0 65 51" fill="none" xmlns="https://www.w3.org/2000/svg">
                                    <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </div>
                                <div className="check-shadow"></div>
                              </div>
                              <h4>"Thank you - We'll be in touch shortly to assist you."</h4>
                            </div>
                          </div>
                        )}
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>

  );
}